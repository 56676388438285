<template>
    <v-app style="background: transparent">
        <div class="kt-portlet">
            <div class="kt-portlet__body kt-portlet__body--fit">
                <div
                        class="kt-grid  kt-wizard-v1 kt-wizard-v1--white"
                        id="kt_wizard_v1"
                        data-ktwizard-state="step-first"
                >
                    <div class="kt-grid__item">
                        <!--begin: Form Wizard Nav -->
                        <div class="kt-wizard-v1__nav">
                            <div class="kt-wizard-v1__nav-items kt-wizard-v1__nav-items--clickable">
                                <!--doc: Replace A tag with SPAN tag to disable the step link click -->
                                <div class="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                    <div class="kt-wizard-v1__nav-body">
                                        <div class="kt-wizard-v1__nav-icon">
                                            <i class="flaticon-list"></i>
                                        </div>
                                        <div class="kt-wizard-v1__nav-label">
                                            1. Detalles generales
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                    <div class="kt-wizard-v1__nav-body">
                                        <div class="kt-wizard-v1__nav-icon">
                                            <i class="flaticon-file-2"></i>
                                        </div>
                                        <div class="kt-wizard-v1__nav-label">
                                            2. Plantilla de contrato
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v1__nav-item" data-ktwizard-type="step"
                                     style="pointer-events: none;">
                                    <div class="kt-wizard-v1__nav-body">
                                        <div class="kt-wizard-v1__nav-icon">
                                            <i class="flaticon-cogwheel"></i>
                                        </div>
                                        <div class="kt-wizard-v1__nav-label">
                                            3. Pasos de contrato
                                        </div>
                                    </div>
                                </div>
                                <div class="kt-wizard-v1__nav-item" data-ktwizard-type="step">
                                    <div class="kt-wizard-v1__nav-body">
                                        <div class="kt-wizard-v1__nav-icon">
                                            <i class="flaticon2-checkmark"></i>
                                        </div>
                                        <div class="kt-wizard-v1__nav-label">
                                            5. Revisar y guardar
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--end: Form Wizard Nav -->
                    </div>
                    <div class="kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper">
                        <!--begin: Form Wizard Form-->
                        <form class="kt-form" id="kt_form">
                            <!--begin: Form Wizard Step 1-->
                            <div class="kt-wizard-v1__content"
                                 data-ktwizard-type="step-content"
                                 data-ktwizard-state="current">
                                <div class="kt-heading kt-heading--md">
                                    Ingrese los datos del contrato
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <b-form class="kt-wizard-v1__form">
                                        <b-form-group id="contract-group-1"
                                                      label="Nombre"
                                                      label-for="contract-group-1" ref="formObj">
                                            <b-form-input id="contract-name"
                                                          name="contract-name"
                                                          placeholder="Nombre del contrato"
                                                          v-model="$v.form.name.$model"
                                                          :state="validateState('name')"
                                                          aria-describedby="contract-name-live-feedback"></b-form-input>

                                            <b-form-invalid-feedback id="contract-name-live-feedback">
                                                El nombre de contrato es requerido
                                            </b-form-invalid-feedback>
                                        </b-form-group>

                                        <b-form-group id="contract-group-2"
                                                      label="Descripción"
                                                      label-for="contract-group-2">
                                            <b-form-input
                                                    type="text"
                                                    id="contract-description"
                                                    name="contract-description"
                                                    placeholder="Descripcion del contrato"
                                                    v-model="$v.form.description.$model"
                                                    :state="validateState('description')"
                                                    aria-describedby="contract-description-live-feedback"></b-form-input>

                                            <b-form-invalid-feedback id="contract-description-live-feedback">
                                                La descripción del contrato es requerida
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <b-form-group id="contract-group-3"
                                                      label="Precio"
                                                      label-for="contract-group-3">
                                            <b-form-input
                                                    step="0.1"
                                                    min="0"
                                                    max="99.9"
                                                    type="number"
                                                    id="contract-price"
                                                    name="contract-price"
                                                    placeholder="Precio"
                                                    v-model="$v.form.price.$model"
                                                    :state="validateState('price')"
                                                    aria-describedby="contract-price-live-feedback"></b-form-input>

                                            <b-form-invalid-feedback id="contract-price-live-feedback">
                                                El precio ingresado no es válido
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                        <b-form-group
                                                id="contract-user"
                                                label="Seleccione un usuario corporativo"
                                                label-for="contract-user"
                                        >
                                            <b-form-select id="contract-user"
                                                           name="contract-user"
                                                           v-model="$v.form.user_id.$model"
                                                           :options="users"
                                                           text-field="firstname"
                                                           value-field="id"
                                                           :state="validateState('user_id')"
                                                           aria-describedby="contract-user-live-feedback">

                                            </b-form-select>

                                            <!--                                                <b-form-invalid-feedback id="input-0-live-feedback">-->
                                            <!--                                                    El nombre es requerido.-->
                                            <!--                                                </b-form-invalid-feedback>-->
                                        </b-form-group>

                                        <label>Color</label>
                                        <v-color-picker class="ma-2" v-model="form.color" hide-inputs></v-color-picker>


                                    </b-form>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 1-->

                            <!--begin: Form Wizard Step 2-->
                            <div class="kt-wizard-v1__content"
                                 data-ktwizard-type="step-content">
                                <div
                                        role="alert"
                                        v-bind:class="{ show: errors }"
                                        class="alert fade alert-danger"
                                >
                                    <div class="alert-text" v-for="(error, i) in errors" :key="i">
                                        {{ error }}
                                    </div>
                                </div>

                                <div class="kt-heading kt-heading--md">
                                    Ingrese la plantilla del contrato
                                </div>
                                <div class="kt-app__detail--lg">
                                    <editor
                                            api-key="kaq4s6i09iatal8jk510rb87ibk70w7g7bz7h48le4irds9q"
                                            v-model="form.template"
                                            :init="{
                                                 entity_encoding : 'raw',
                                                 height: 500,
                                                 menubar: true,
                                                 plugins: [
                                                   'advlist autolink lists link image charmap print preview anchor',
                                                   'searchreplace visualblocks code fullscreen',
                                                   'insertdatetime media table paste code help wordcount',
                                                   'table'
                                                 ],
                                                 toolbar:
                                                   'undo redo | formatselect | bold italic backcolor | \
                                                   alignleft aligncenter alignright alignjustify | \
                                                   bullist numlist outdent indent | removeformat | help\
                                                   table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol'
                                               }"
                                    />
                                    <h5>{{variables}}</h5>
                                </div>

                            </div>
                            <!--end: Form Wizard Step 2-->

                            <!--begin: Form Wizard Step 3-->
                            <div class="kt-wizard-v1__content"
                                 data-ktwizard-type="step-content">
                                <!--                            <div class="kt-heading kt-heading&#45;&#45;md">Formularios del contrato</div>-->
                                <step v-if="wizard && wizard.currentStep===3" ref="steps" :variables=variables
                                      :contract=form :steps="steps"></step>
                            </div>
                            <!--end: Form Wizard Step 3-->
                            <div
                                    class="kt-wizard-v1__content"
                                    data-ktwizard-type="step-content"
                            >
                                <div class="kt-heading kt-heading--md">
                                    Resumen de contrato
                                </div>
                                <div class="kt-form__section kt-form__section--first">
                                    <div class="kt-wizard-v1__review">
                                        <div class="kt-wizard-v1__review-item">
                                            <div class="kt-wizard-v1__review-title">
                                                Datos generales
                                            </div>
                                            <div class="kt-wizard-v1__review-content">
                                                {{form.name}}<br/>
                                                {{form.description}}<br/>
                                                {{form.price}}
                                            </div>
                                        </div>
                                        <div class="kt-wizard-v1__review-item">
                                            <div class="kt-wizard-v1__review-title">
                                                Formularios
                                            </div>
                                            <div class="kt-wizard-v1__review-content">
                                                <p v-for="form in steps" :key="form.id"><strong>{{form.name}}</strong><br/>
                                                    {{form.description}}
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!--end: Form Wizard Step 5-->

                            <!--begin: Form Actions -->
                            <div class="kt-form__actions">
                                <button class="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                        data-ktwizard-type="action-prev">
                                    Anterior
                                </button>
                                <button class="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                                        data-ktwizard-type="action-next" @click="goNext()">
                                    Siguiente
                                </button>
                            </div>
                            <!--end: Form Actions -->
                        </form>
                        <!--end: Form Wizard Form-->
                    </div>
                </div>
            </div>
            <v-dialog v-model="dialog"
                      hide-overlay
                      persistent
                      width="300">
                <v-card color="primary"
                        dark>
                    <v-card-text>
                        Cargando...
                        <v-progress-linear
                                indeterminate
                                color="white"
                                class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </div>
    </v-app>

</template>

<style lang="scss">
    @import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>


    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import KTUtil from "@/assets/js/util";
    import KTWizard from "@/assets/js/wizard";
    import {minLength, required} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import service from "@/service/contract.service"
    import usersService from "@/service/users.service"
    import Editor from '@tinymce/tinymce-vue'
    import {ADD_BREADCRUMB} from "../../../store/breadcrumbs.module";

    const minValue = (value) => value > 0;
    const maxValue = (value) => value < 99.9;

    export default {
        name: "contract-register",
        mixins: [validationMixin],
        data() {
            return {
                users: [],
                form: {
                    id: undefined,
                    name: "",
                    description: "",
                    template: "",
                    price: "",
                    user_id: "",
                    color: "#8CA039",
                },
                wizard: undefined,
                editorObj: undefined,
                regex: /\{{\$(.*?)\}}/gm,
                conditionalRegex: /\$([a-z|A-Z|0-9 |_].*?)+/gm,
                errors: undefined,
                dialog: false,
                steps: []
                // contract: {}

            };
        },
        components: {
            step: () => import("./StepsRegister"),
            'editor': Editor
        },
        validations: {
            form: {
                name: {
                    required,
                    minLength: minLength(5)
                },
                description: {
                    required,
                    minLength: minLength(5)
                },
                template: {},
                color: {},
                user_id: {},
                price: {
                    required,
                    maxValue: maxValue,
                    minValue: minValue
                }
            }
        },
        mounted() {
            this.dialog = true;
            this.editorObj = this.$refs.editor;
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Documentos"}, {title: this.$route.params.id ? "Editar contrato" : "Registro de contratos"}
            ]);
            if (this.$route.params.id) {
                this.dialog = true;
                service.get(this.$route.params.id).then(x => {
                    const result = x.data.data;
                    this.form.id = result.id;
                    this.form.name = result.nombre;
                    this.form.description = result.descripcion;
                    this.form.price = result.precio;
                    this.form.template = result.template;
                    this.form.color = result.color;
                    this.form.user_id = result.user_id;
                    this.$v.form.$touch();
                    this.$store.dispatch(ADD_BREADCRUMB, [{title: result.nombre}]);

                }).finally(() => {
                    this.dialog = false
                });
            }
            usersService.getPath('corporativo').then(x => {
                this.users = [{firstname: 'Ninguno'}, ...x.data.data];
            }).finally(() => {
                this.dialog = false
            });


            // Initialize form wizard
            const wizard = new KTWizard("kt_wizard_v1", {
                startStep: 1, // initial active step number
                clickableSteps: true // allow step clicking
            });
            this.wizard = wizard;

            let elements = document.getElementsByClassName("tox-statusbar__branding");
            elements.forEach(x => {
                x.style.visibility = 'hidden';
            });
            wizard.on("change", function (/*wizardObj*/) {
                setTimeout(function () {
                    KTUtil.scrollTop();
                }, 500);
            });
        },
        methods: {
            goNext() {
                switch (this.wizard.currentStep) {
                    case 1:
                        this.$v.form.$touch();
                        if (this.$v.form.$anyError) {
                            this.wizard.stop()
                        }
                        break;
                    case 2:
                        this.wizard.stop();
                        if (this.variables.length <= 0) {
                            this.errors = ["La plantilla ingresada no es válida"];
                        } else {
                            const params = {
                                nombre: this.form.name,
                                descripcion: this.form.description,
                                precio: this.form.price,
                                template: this.form.template,
                                color: this.form.color,
                                user_id: this.form.user_id
                            };
                            this.dialog = true;

                            service.register(params, this.form.id).then(x => {
                                if (this.form.id) {
                                    service.getSteps(this.form.id).then(r => {
                                        this.steps = r.data.data;
                                        this.form.id = x.data.data.id;
                                        this.wizard.goNext();
                                    })
                                } else {
                                    this.form.id = x.data.data.id;
                                    this.wizard.goNext();
                                }
                            }).catch(error => {
                                this.errors = error.errors;
                            }).finally((() => {
                                this.dialog = false;
                            }));
                        }
                        break;
                        case 3:
                            this.steps = this.$refs.steps.items;
                            break;
                    // case 3:
                    //     this.dialog = true;
                    //     this.wizard.stop();
                    //     this.$refs.steps.saveSteps().then(() => {
                    //         if(this.form.id) {
                    //             service.getSteps(this.form.id).then(r => {
                    //                 this.steps = r.data.data;
                    //                 this.wizard.goNext();
                    //             })
                    //         }
                    //         else {
                    //             this.wizard.goNext();
                    //         }
                    //     }).catch(() => {
                    //         // console.log(error)
                    //     }).finally(() => {
                    //         this.dialog = false;
                    //     });
                    //
                    //     break;
                    default:
                        this.wizard.goNext();
                        break;

                }
            },

            validateState(name) {
                const {$dirty, $error} = this.$v.form[name];
                return $dirty ? !$error : null;
            },
            findPattern(pattern, result, start) {
                let m;
                while ((m = pattern.exec(this.form.template)) !== null) {
                    if (m.index === this.regex.lastIndex) {
                        pattern.lastIndex++;
                    }
                    const val = start ? m[0].substring(1).trim() : m[1].trim();
                    if (val) {
                        const v = val;
                        const index = result.findIndex(x => {
                            return x === v
                        });
                        if (index < 0) {
                            result.push(val);
                        }
                    }

                }
                return result;
            }
        },
        computed: {
            variables() {
                let result = [];
                this.findPattern(this.regex, result, false);
                this.findPattern(this.conditionalRegex, result, true);
                return result;
            }
        }

    };
</script>
