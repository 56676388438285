import httpService from "../helpers/http.service";

export default {
    register(payload, id){
        let  url = 'users';
        url += id ? '/' + id : '';
        return httpService.post(url , payload);
    },
    get(id){
        let  url = 'users';
        url += id ? '/' + id : '';
        return httpService.get(url);
    },

    getPath(path){
        let  url = 'user/' + path;
        return httpService.get(url);
    },
    delete(id) {
        let  url = 'users/' + id ;
        return httpService.delete(url);
    }
};
